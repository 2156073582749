<template>
  <!-- 版本号管理 -->
  <div>
    <h4 style="color: rgb(155 148 148)">安卓下载链接</h4>
    <div class="whiter">
      <el-form size="medium" style="width: 800px">
        <el-form-item label="更新时间">
          <el-input
            v-model="date.updateTime"
            disabled
            placeholder=""
          ></el-input>
        </el-form-item>
        <el-form-item label="版本号">
          <el-input
            v-model="date.versionNumber"
            clearable
            placeholder=""
          ></el-input>
        </el-form-item>
        <el-form-item label="下载链接">
          <el-input
            v-model="date.address"
            clearable
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button style="margin-left: 20px" type="primary" @click="Submit"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      date: {},
    };
  },
  created() {
    this.edition();
  },
  methods: {
    //   获取版本号信息
    edition() {
      this.axios.get("/admin/versionNumber/showVersion", {}).then((res) => {
        this.date = res.data.data;
      });
    },
    // 提交
    Submit() {
      // var myDate = new Date();
      // var mytime = myDate.toLocaleTimeString();
      // this.date.versionNumber = Number(this.date.versionNumber);
      // this.umber = Number(this.umber);
      // let ulect = this.date.versionNumber + this.umber;
      // ulect = ulect.toFixed(1);
      this.axios
        .put("/admin/versionNumber/updateVersionNumber", this.date)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.$message({
              message: res.mesg,
              type: "success",
            });
            // 重新获取版本号
            this.edition();
          } else {
            this.$message({
              message: res.mesg,
              type: "error",
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.whiter {
  height: 100px;
  border-radius: 15px;
  .aniht {
    margin-bottom: 20px;
  }
  .ios {
    margin-bottom: 20px;
  }
}
</style>
